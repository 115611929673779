import React, { useContext, useEffect, useState } from "react";
import style from "./Modal.module.css";
import Title from "../Title";
import Button from "../Button/Button";
import ButtonsModal from "./ButtonsModal/ButtonsModal";
import { QtdContext } from "../../context/qtdContext";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { addDays, isWeekend } from 'date-fns';
import { registerLocale, setDefaultLocale } from "react-datepicker";
import { ru } from 'date-fns/locale/ru';
registerLocale('ru', ru)
const ModalDatePicker = ({ selectedDate, setSelectedDate }) => {

  const { showDatePicker, setShowDatePicker } = useContext(QtdContext);

  const closeModal = ({ target }) => {
    // console.log(target)
    target.id === "containewer" && setShowDatePicker(false);
    // target.innerText === "ОТМЕНИТЬ" && setShowDatePicker(false);
  };
  const noDates = [new Date('Jun 12 2024'), new Date('May 10 2024'), new Date('May 11 2024'), new Date('May 12 2024')]
  const filterWeekdays = date => {
    console.log('weekends here', noDates)
    // const t = 
    const thisDate = noDates.find(pr => pr.getTime() == date.getTime())
    console.log(thisDate)
    if (thisDate) {
      return false
    }

    console.log(date)
    // Запрещаем выбор выходных дней (суббота и воскресенье)
    return !isWeekend(date);
  };
  const handleDateChange = date => {
    console.log(date)
    setSelectedDate(date);
    setShowDatePicker(false); // Закрываем дейтпикер после выбора даты

  };
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  return (<>
    {showDatePicker && (
      <div onClick={closeModal} id="containewer" className={style.modal_container}>
        {/* <div className={style.modal}> */}

        <DatePicker
          locale="ru"
          selected={selectedDate}
          onChange={handleDateChange}
          filterDate={filterWeekdays}
          minDate={tomorrow}
          dateFormat="dd/MM/yyyy"
          placeholderText="Выберите дату доставки"
          inline
        />
        {/* </div> */}
      </div>
    )}
  </>
  );
};

export default ModalDatePicker;
