import React, { useContext, useEffect, useState } from "react";
import style from "./ModalInfo.module.css";
// import ButtonsModal from "./ButtonsModal/ButtonsModal";
import Button from "../../../../Button/Button";
import { QtdContext } from "../../../../../context/qtdContext";
import { BiTrash } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom';
import Title from "../../../../Title";
import $api from '../../../../../http/index.ts'
import { FcCancel, FcServices } from "react-icons/fc";
import { confirmAlert } from 'react-confirm-alert'; // Import
import '../NewOrders/react-confirm-alert.css'; // Import css
import ModalEditProduct from "./ModalEditProduct";
import ModalAddProduct from "./ModalAddProduct";
import { BACKURL } from '../../../../../conf.js'
const ModalInfo = ({ status }) => {

    // const navigate = useNavigate();
    // const produtosCart = JSON.parse(localStorage.getItem("pet"));
    // const { setSclad,setServerProd, auth, setAuth } = useContext(QtdContext);

    const { opt, servOrders, setServOrders, modalAddProduct, setModalAddProduct, modalEditProduct, setModalEditProduct, infomodal, modalInfo, setModalInfo, admin, setSclad, setServerProd, modalEdit, setModalEdit } = useContext(QtdContext);
    const closeModal = ({ target }) => {
        // console.log(modalInfo)
        target.id === "container123" && setModalInfo(false);
        // target.innerText === "ОТМЕНИТЬ" && setModal(false);
    };
    // console.log(infomodal)
    let data = infomodal.data
    if (infomodal.what == 'Адрес Доставки' && infomodal.type == "Розница") {
        data = JSON.parse(infomodal.data)
    }

    // console.log(infomodal)
    // console.l
    // useEffect(()=>{
    //         console.log('infomodal', infomodal)

    // },[])
    const [SoSoName, setSosoName] = useState('')
    const [SoSoProd, setSosoProd] = useState('')
    const changeQty = async (item) => {
        try {
            // console.log('change', item)
            setSosoProd(item)
            setSosoName(item.name)
            setModalEditProduct(true)
        } catch (e) {
            // console.log(e)
        }
    }
    const deleteIt = async (item) => {
        try {
            // console.log('delete', item.name, infomodal.order_id)
            const newProd = await $api.post(`${BACKURL}/api/utbteam/deleteorderproduct/${infomodal.order_id}`,
                { name: item.id, admin: admin.user }

            )
            const newPosts = await servOrders.map((post) => (
                post.id == infomodal.order_id
                    ? newProd.data
                    : post
            ));

            setServOrders(newPosts)
            // setSclad(newPosts)

            // //Проверь правильность данных тут
            // setServerProd(newPosts)
            // console.log(newProd.data)
            setModalInfo(false)
            // localStorage.setItem("petNew", JSON.stringify(newPosts));
            // setModalEditProduct(false)
            alert('Товар успешно удален из заказа!')

        } catch (e) {
            // console.log(e)
        }
    }
    const submit = async (func, data) => {
        confirmAlert({
            title: 'Подтвердите, чтобы продолжить!',
            message: 'Вы уверены, в том, что хотите удалить позицию из заказа?',
            buttons: [
                {
                    label: 'Да!',
                    onClick: async () => await func(data)
                },
                {
                    label: 'Нет!',
                    //   onClick: () => alert('Click No')
                }
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
        });
    };


    return (
        <>
            {modalInfo && (
                <div onClick={closeModal} id="container123" className={style.modal_container}>

                    <div className={style.modal}>
                        <div>
                            <Title text={infomodal.what}></Title>
                        </div>
                        {infomodal.what == 'Адрес Доставки' && infomodal.type == "Розница" && (<>
                            <p><b>Город:</b>{data.city}</p>
                            <p><b>Улица:</b>{data.street}</p>
                            <p><b>Дом/Подьезд:</b>{data.house}</p>
                            <p><b>Квартира/Офис:</b>{data.flat}</p>
                        </>)}
                        {infomodal.what == 'Заказ' && infomodal.type == "Розница" && (<>
                            {status === 'Ожидает подтверждения!' &&
                                <Button
                                    text="Добавить Продукт"

                                    background="var(--primary-color)"
                                    color="white"
                                    width="100%"
                                    onClick={() => setModalAddProduct(true)}
                                />
                            }
                            <div style={{ overflowX: 'auto',width:'100%' }}>
                                <table className={style.table}>
                                    <thead className={style.tableth}>
                                        <tr>
                                            <td className={style.tabletd}>№</td>
                                            <td className={style.tabletd}>Наименование</td>
                                            <td className={style.tabletd}>Кол-во</td>
                                            {(status === 'Ожидает подтверждения!' || status === 'Ожидает оплаты!' || status === 'Оплачен!'|| status === 'Получен!') && (<>
                                                <td className={style.tabletd}>Цена</td>
                                                <td className={style.tabletd}>Скидка</td>
                                                <td className={style.tabletd}>Стоимость</td>
                                                {status === 'Ожидает подтверждения!' && (

                                                    <td className={style.tabletd}>Действия</td>
                                                )}

                                            </>
                                            )}
                                        </tr>

                                    </thead>
                                    <tbody>
                                        {data.map((prod, index) => {
                                            const item = (JSON.parse(prod))
                                            return (<tr>
                                                <td className={style.tabletd}>{index + 1}</td>
                                                <td className={style.tabletd}>{item.name}</td>
                                                <td className={style.tabletd}>{item.qu}шт</td>
                                                {(status === 'Ожидает подтверждения!' || status === 'Ожидает оплаты!'|| status === 'Оплачен!'|| status === 'Получен!') && (<>
                                                    <td className={style.tabletd}>{item.oldprice}RUB</td>
                                                    <td className={style.tabletd}>{item.sale}</td>
                                                    <td className={style.tabletd}>{item.price}RUB</td>
                                                    {status === 'Ожидает подтверждения!' && (

                                                        <td style={{ display: 'flex', justifyContent: 'spaceAround' }} className={style.tabletd}><FcServices onClick={() => changeQty(item)} /><FcCancel onClick={() => submit(deleteIt, item)} /></td>
                                                    )}
                                                </>)}
                                            </tr>)
                                        }

                                        )}
                                    </tbody>
                                </table>
                            </div>
                            {modalEditProduct && (
                                <ModalEditProduct
                                    prod={SoSoProd}
                                    name={SoSoName}
                                />

                            )}
                            {modalAddProduct && (
                                <ModalAddProduct
                                // status={SoSoProd}
                                // name={SoSoName}
                                />

                            )}
                        </>
                        )}
                        {infomodal.what == 'Адрес Доставки' && infomodal.type == "Опт" && (<>
                            <p>{infomodal.data}</p>
                        </>)}
                        {infomodal.what == 'Заказ' && infomodal.type == "Опт" && (<>
                            {status === 'Ожидает подтверждения!' &&
                                <Button
                                    text="Добавить Продукт"

                                    background="var(--primary-color)"
                                    color="white"
                                    width="100%"
                                    onClick={() => setModalAddProduct(true)}
                                />
                            }
                            <div style={{ overflowX: 'auto' }}>
                                <table className={style.table}>
                                    <thead className={style.tableth}>
                                        <tr>
                                            <td className={style.tabletd}>№</td>
                                            <td className={style.tabletd}>Наименование</td>
                                            <td className={style.tabletd}>Кол-во</td>
                                            {status === 'Ожидает подтверждения!' && (<>
                                                <td className={style.tabletd}>Цена</td>

                                                <td className={style.tabletd}>Стоимость</td>
                                                <td className={style.tabletd}>Скидка</td>

                                                <td className={style.tabletd}>Действия</td>
                                            </>
                                            )}
                                        </tr>

                                    </thead>
                                    <tbody>
                                        {data.map((prod, index) => {
                                            const item = (JSON.parse(prod))
                                            return (<tr>
                                                <td className={style.tabletd}>{index + 1}</td>
                                                <td className={style.tabletd}>{item.name}</td>
                                                <td className={style.tabletd}>{item.quantidade}шт</td>
                                                {status === 'Ожидает подтверждения!' && (<>
                                                    <td className={style.tabletd}>{item.price}RUB</td>
                                                    <td className={style.tabletd}>{Number(item.quantidade) * Number(item.price)}RUB</td>
                                                    <td className={style.tabletd}>0</td>

                                                    <td style={{ display: 'flex', justifyContent: 'spaceAround' }} className={style.tabletd}><FcServices onClick={() => changeQty(item)} /><FcCancel onClick={() => submit(deleteIt, item)} /></td>
                                                </>)}
                                            </tr>)
                                        }

                                        )}
                                    </tbody>
                                </table>
                            </div>
                            {modalEditProduct && (
                                <ModalEditProduct
                                    prod={SoSoProd}
                                    name={SoSoName}
                                />

                            )}
                            {modalAddProduct && (
                                <ModalAddProduct
                                // status={SoSoProd}
                                // name={SoSoName}
                                />

                            )}
                        </>
                        )}
                        {/* <Button
                            text="Изменить цену"

                            background="var(--primary-color)"
                            color="white"
                            width="100%"
                            onClick={editPrice}
                        /> */}
                    </div>

                </div>

                // </div>
            )}

        </>
    );
};

export default ModalInfo;
