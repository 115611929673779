import { BrowserRouter, Route, Routes, useNavigate, useLocation, Navigate } from "react-router-dom";
import Header from "./Components/Header/Header";
import Home from "./Components/Pages/Home/Home";
import  "./App.css";

// import style from "./App.css";
import { QtdContext } from "./context/qtdContext";
import Footer from "./Components/Footer/Footer";
import Sobre from "./Components/Pages/Sobre/Sobre";
import Loja from "./Components/Pages/Loja/Loja";
import QtdProvider from "./context/qtdContext";
import MenuMobile from "./Components/Header/MenuMobile/MenuMobile";
import { useEffect, useState, useContext } from "react";
import { Loading } from "./Components/Loading/Loading";
import Contato from "./Components/Pages/Contato/Contato";
import Order from "./Components/Pages/Order/Order";
import axios from "axios";
import Success from "./Components/Pages/Order/Sucess";
import ProdInfo from "./Components/Pages/ProdInfo/ProdInfo";
import Zakaz from "./Components/Pages/Zakaz/Zakaz";
import Admin from "./Components/Pages/Admin/Admin";
import Racions from "./Components/Pages/Racion/Racion";
import OptUser from "./Components/Pages/OptUser/OptUser";
import OptOrder from "./Components/Pages/Order/OptOrder";
import RedirectQR from "./redirect";
import ClicksService from "./services/clicks";
import Moroz from "./Components/Pages/Moroz/Moroz";
import SuhKorma from "./Components/Pages/SuhKorma/SuhKorma";
import Farsh from "./Components/Pages/Farsh/Racion";
import AmunitionData from "./Components/Produtos/AmunationData";
import Amunition from "./Components/Pages/Amunation/Racion";
import ForDogs from "./Components/Pages/ForDogs/ForDogs";
import ForCats from "./Components/Pages/ForCats/ForCats";
function App() {
  const [loading, setLoading] = useState(false);
  // const location = useLocation()

  // useEffect(() => {
  //   const test = async () => {
  //     // await ClicksService.addone('Запуск Сайта')
  //     setTimeout(() => {

  //       setLoading(false)
  //     }, 500);
  //   }
  //   // test()

  // }, [])

  return (
    <QtdProvider 
    // setLoading={setLoading}
    >
   
   
   <div className="bg"></div>
<div className="content">

      {/* {loading ? <Loading /> : */}
        <BrowserRouter>
          <div className="mobile_app">
            <MenuMobile />
          </div>
          <div className="header_app">
            <Header />
          </div>
          <Routes>
            <Route path="*" element={<Navigate to="/" />} />
            <Route path="/" element={<Home />}></Route>
            <Route path="/cuns" element={<Sobre />}></Route>
            <Route path="/lacomstva" element={<Loja />}></Route>
            <Route path="/contacts" element={<Contato />}></Route>
            <Route path="/prodinfo/:imageid" element={<ProdInfo />}></Route>
            <Route path="/racions" element={<Racions />}></Route>
            <Route path="/suhkorm" element={<SuhKorma />}></Route>
            <Route path="/zamorozka" element={<Moroz />}></Route>
            <Route path="/farsh" element={<Farsh />}></Route>
            <Route path="/partners" element={<OptUser />}></Route>
            <Route path="/fordogs" element={<ForDogs />}></Route>
            <Route path="/forcats" element={<ForCats />}></Route>
            <Route path="/amunation" element={<Amunition/>}></Route>
            {/* <Route path="/zakaz" element={<Zakaz/>}></Route> */}

            <Route path="/createorder" element={<Order />}></Route>
            <Route path="/success/:id" element={<Success />}></Route>
            <Route path="/utbteam" element={<Admin />}></Route>
            <Route path="/createoptorder" element={<OptOrder />}></Route>
            <Route path="/redirect/:name" element={<RedirectQR />}></Route>



          </Routes>
          <div className='ftr'>
          <Footer />
          </div>
        </BrowserRouter>
  {/* //  } */}
   </div>
    
    </QtdProvider>
  
  );
}

export default App;